import type { ReactNode } from "react";
import React from "react";
import { PagePartnerLogin } from "@aspen/pages";

class Login extends React.Component {
  render(): ReactNode {
    return <PagePartnerLogin />;
  }
}

export default Login;
